<template>
	
	<div style="background: #fff;padding: 15px;" class="ml-goodList" v-loading = "pageloading">
		<!-- tab切换 -->
		<div class="good-tab">
			<el-tabs v-model="listState" @tab-click="filterFun">
				<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
			</el-tabs>
		</div>

		<div class="filter-container">
			<div class="filter-item">
				<label class="label text-right-80">关键字: </label>
				<el-input v-model="searchKey" placeholder="商品名称" style="width:250px;margin-right: 10px;" clearable
				 @keyup.enter.native="goodsFilter"></el-input>
			</div>
		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label text-right-80">商品标签: </label>
				<el-select v-model="goodsLabel" placeholder="请选择" style="width: 200px;margin-right: 10px;" filterable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in goodsLabelList" :key="item.TagId" :label="item.TagName" :value="item.TagId"></el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label text-right-80">商城状态: </label>
				<el-select v-model="mallState" placeholder="请选择" style="width: 200px;">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in mallStateList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label text-right-80">价格区间: </label>
				<el-input v-model="priceMin" placeholder="最小价格" style="width: 130px;" clearable
          oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
				<span style="margin:0 10px;color:#606266">~</span>
				<el-input v-model="priceMax" placeholder="最大价格" style="width: 130px;" clearable
          oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" @click="goodsFilter">查询</el-button>
				<buttonPermissions :datas="'taokeDistributionShopGather'">
					<el-button type="primary" :class="{'BtnMask':result.RecordType==1&&result.SyncState==0}"
           @click="toGather" style="margin-left:10px">{{result.RecordType==1&&result.SyncState==0?'采集中...':'采集商品'}}</el-button>
				</buttonPermissions>
				<buttonPermissions :datas="'taokeDistributionShopUpdate'">
					<el-button type="primary" :class="{'BtnMask':result.RecordType==2&&result.SyncState==0}" 
           @click="updateAll()" style="margin-left:10px">{{result.RecordType==2&&result.SyncState==0?'更新中...':'更新商品'}}</el-button>
				</buttonPermissions>
				<el-button type="text" @click="clearSearch" style="margin-left:10px">重置筛选条件</el-button>
			</div>
		</div>
    <div class="absolute-icon" :class="{'packUp':showHidden}" v-if="result.Id>0">
      <i class="el-icon-d-arrow-right side" @click="showHidden=!showHidden"></i>
      <div class="content">
        <div class="icon-box">
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/loading-funnel.gif" v-if="result.SyncState==0" alt="">
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/loading-success.png" v-else alt="">
        </div>
        <div class="newly font-12 color-999999" style="text-align:center" v-if="result.SyncState==1&&result.RecordType==1">新增{{result.AddProductCount}}件</div>
        <div class="bottom" :style="{background:result.SyncState==0?'#409EFF':'#13ce66'}" v-if="result.RecordType==1">{{result.SyncState==0?'商品采集中...':'商品采集完成'}}</div>
        <div class="bottom" :style="{background:result.SyncState==0?'#409EFF':'#13ce66'}" v-else-if="result.RecordType==2">{{result.SyncState==0?'商品更新中...':'商品更新完成'}}</div>
      </div>
    </div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading" ref="multipleTable" @selection-change="handleSelectionChange" class="table">
				<el-table-column type="selection" width="55" fixed="left"></el-table-column>
				<el-table-column label="商品" width="360" fixed="left">
					<template slot-scope="scope">
						<div class="product-info" style="align-items:flex-start">
							<img :src="scope.row.ImgUrl">
							<div style="width:0" class="flex-1">
								<div class="ellipsis-two">
									{{scope.row.Name}}
								</div>
								<div class="ellipsis" style="color:#909399;width:100%" v-if="scope.row.GoodsId">ID:{{scope.row.GoodsId}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="优惠券">
					<template slot-scope="scope">
            <div>
              <el-popover placement="top" trigger="click" v-if="scope.row.IsHasCoupon">
                <div class="font-14">
                  生效时间：{{scope.row.CouponStartTime}}至{{scope.row.CouponEndTime}}
                </div>
                <el-button type="text" slot="reference">￥{{scope.row.CouponDiscount}}</el-button>
              </el-popover>
              <div v-else>--</div>
            </div>
					</template>
				</el-table-column>

				<el-table-column prop="" label="价格" width="140">
					<template slot-scope="scope">
						<div class="font-14">
              <div class="color-606266">拼团价：<span >￥{{scope.row.MinGroupPrice}}</span> </div>
              <div class="color-999999">单买价：￥{{scope.row.MinNormalPrice}}</div>
            </div>
					</template>
				</el-table-column>
				<el-table-column prop="" label="佣金" width="140">
					<template slot-scope="scope">
						<div class="font-14">
              <div class="color-606266">金额：<span class="color-f56c6c">￥{{scope.row.PromotionMoney}}</span> </div>
              <div class="color-999999">比例：{{scope.row.PromotionRateValue}}</div>
            </div>
					</template>
				</el-table-column>
				<el-table-column prop="" label="标签" min-width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.TagNameList&&scope.row.TagNameList.length">
              {{scope.row.TagNameList.join('/')}}
            </div>
          </template>
        </el-table-column>
				<el-table-column prop="SalesTip" label="销量"></el-table-column>
				<el-table-column prop="PlatformTypeValue" label="平台" width="150"></el-table-column>
				<el-table-column prop="" label="店铺" width="150">
          <template slot-scope="scope">
						<div>{{scope.row.TaoKeStoreName}}</div>
					</template>
        </el-table-column>
				<!-- <el-table-column prop="" label="多多状态">
          <template slot-scope="scope">
						<div :class="scope.row.TaoKeIsOnSale?'':'color-f56c6c'">{{scope.row.TaoKeIsOnSale?'上架':'下架'}}</div>
					</template>
        </el-table-column> -->
				<el-table-column prop="" label="商城状态" width="150">
					<template slot-scope="scope">
            <el-radio-group v-model="scope.row.MallIsOnSale" size="mini" @change="(e)=>{changeMallState([scope.row],e)}">
              <el-radio-button :label="true">显示</el-radio-button>
              <el-radio-button :label="false">隐藏</el-radio-button>
            </el-radio-group>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="添加时间" width="170"></el-table-column>
				<el-table-column prop="LastUpdateTime" label="最近更新时间" width="170"></el-table-column>
				<el-table-column label="操作" width="200" fixed="right">
					<template slot-scope="scope">
            <div class="flex gap-10">
              <buttonPermissions :datas="'taokeDistributionShopUpdate'">
                <el-button type="text" @click="toUpdate(scope.row)">更新</el-button>
              </buttonPermissions>
              <buttonPermissions :datas="'taokeDistributionShopWinnow'">
                <el-button type="text" @click="setWinnow([scope.row],!scope.row.IsBoutique)">{{scope.row.IsBoutique?'取消':'设为'}}精选</el-button>
              </buttonPermissions>
              <buttonPermissions :datas="'taokeDistributionShopDel'">
                <el-button type="text" style="color:#f56c6c" @click="del([scope.row])">删除</el-button>
              </buttonPermissions>
            </div>
					</template>
				</el-table-column>

			</el-table>

			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin:20px 0 0 13px;">
					<el-checkbox @change="tabCheckedChange" :value="checked">当前页全选</el-checkbox>

					<buttonPermissions2 :datas="['taokeDistributionShopDel','taokeDistributionShopUpdate','taokeDistributionShopWinnow']">

						<el-dropdown style="margin-left:15px" @command="handleCommand">
							<el-button size="mini" class="el-dropdown-link color-606266">
								批量操作
							</el-button>
							<el-dropdown-menu slot="dropdown">
                <div class="flex flex-column">
                  <buttonPermissions :datas="'taokeDistributionShopDel'">
                    <el-dropdown-item command="1">
                      <el-button type="text" class="color-606266">删除</el-button>
                    </el-dropdown-item>
                  </buttonPermissions>
                  <buttonPermissions :datas="'taokeDistributionShopUpdate'">
                    <el-dropdown-item command="2">
                      <el-button type="text" class="color-606266">更新</el-button>
                    </el-dropdown-item>
                  </buttonPermissions>
                  <buttonPermissions :datas="'taokeDistributionShopWinnow'">
                    <el-dropdown-item command="3">
                      <el-button type="text" class="color-606266">取消精选</el-button>
                    </el-dropdown-item>
                  </buttonPermissions>
                  <buttonPermissions :datas="'taokeDistributionShopWinnow'">
                    <el-dropdown-item command="4">
                      <el-button type="text" class="color-606266">设为精选</el-button>
                    </el-dropdown-item>
                  </buttonPermissions>
                  <el-dropdown-item command="5">
                    <el-button type="text" class="color-606266">商城内隐藏</el-button>
                  </el-dropdown-item>
                  <el-dropdown-item command="6">
                    <el-button type="text" class="color-606266">商城内显示</el-button>
                  </el-dropdown-item>
                </div>
							</el-dropdown-menu>
						</el-dropdown>
					</buttonPermissions2>

				</div>
				<el-pagination v-if="Total" style="margin:20px 0;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>


    <el-dialog :visible.sync="gatherShow" title="采集商品" custom-class="dialog-body-paddingTop-10" width="700px" v-loading="gatherLoading">
      <div class="tips">
        关键字和其余条件不可同时设置(若同时设置，拼多多将以关键字为条件查询商品，忽略其余条件)
      </div>
      <div class="form" style="margin-top:10px">
        <div class="flex flex-align-center">
          <div class="form-label keyLabel">采集平台：</div>
          <el-radio-group v-model="gatherMsg.PlatformType">
            <el-radio :label="1">拼多多进宝</el-radio>
          </el-radio-group>
        </div>
        <div class="flex flex-align-center margin-top-20">
          <div class="form-label keyLabel">关键字：</div>
          <el-input v-model="gatherMsg.Keywords" style="width:393px" placeholder="输入商品查询关键字，比如洗脸巾" maxlength="1000"></el-input>
        </div>
        <div class="flex flex-align-center margin-top-20">
          <div class="form-label keyLabel">商品标签：</div>
          <el-radio-group v-model="gatherMsg.TagType">
            <el-radio :label="0">不限</el-radio>
            <el-radio :label="1">采集指定标签商品</el-radio>
            <el-radio :label="2">采集精选商品</el-radio>
          </el-radio-group>
        </div>
        <div class="flex flex-align-center margin-top-20" v-if="gatherMsg.TagType==1">
          <div class="form-label keyLabel">选择标签：</div>
          <el-select v-model="gatherMsg.TaoKeQidianTagId" style="width:393px" placeholder="请选择标签">
					  <el-option v-for="item in goodsLabelList" :key="item.TagId" :label="item.TagName" :value="item.TagId"></el-option>
          </el-select>
        </div>
        <div class="flex margin-top-20">
          <div class="form-label keyLabel">选择区间：</div>
          <div>
            <el-radio-group v-model="gatherMsg.chooseOpt">
              <div class="flex flex-align-center gap-10 font-14">
                <el-radio :label="0" style="margin:0px"> <span class="font-14">价格区间</span> </el-radio>
                <div class="flex flex-align-center gap-10">
                  <el-input v-model="gatherMsg.MinPrice" style="width:140px"
                    oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
                    <template slot="append">元</template>
                  </el-input>
                  <span>~</span>
                  <el-input v-model="gatherMsg.MaxPrice" style="width:140px"
                    oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
                    <template slot="append">元</template>
                  </el-input>
                </div>
              </div>
              <div class="flex flex-align-center gap-10 font-14 margin-top-20">
                <el-radio :label="1" style="margin:0px"> <span class="font-14">佣金区间</span> </el-radio>
                <div class="flex flex-align-center gap-10">
                  <el-select v-model="gatherMsg.PromotionType" style="width:110px" placeholder="请选择">
                    <el-option v-for="(v,i) in gatherOption" :key="i" :label="v.label" :value="v.value"></el-option>
                  </el-select>
                  <div class="flex flex-align-center gap-10" v-if="gatherMsg.PromotionType==1">
                    <el-input v-model="gatherMsg.MinPromotion" style="width:140px"
                      oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
                      <template slot="append">%</template>
                    </el-input>
                    <span>~</span>
                    <el-input v-model="gatherMsg.MaxPromotion" style="width:140px"
                      oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                  <div class="flex flex-align-center gap-10" v-else>
                    <el-input v-model="gatherMsg.MinPromotion" style="width:140px"
                      oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                    <span>元及以上</span>
                  </div>
                </div>
              </div>
              <div class="flex flex-align-center gap-10 font-14 margin-top-20">
                <el-radio :label="2" style="margin:0px"> <span class="font-14">销量区间</span> </el-radio>
                <div class="flex flex-align-center gap-10">
                  <el-input v-model="gatherMsg.MinSaleCount" style="width:140px"
                    onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                    onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
                  <span>件及以上</span>
                </div>
              </div>
            </el-radio-group>
          </div>
          
        </div>
        <div class="flex flex-align-center margin-top-20">
          <div class="form-label keyLabel">采集数量：</div>
          <el-input v-model="gatherMsg.GatherCount" style="width:140px"
            onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
            onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
            <template slot="append">件</template>
          </el-input>
        </div>
        <div slot="footer" class="margin-top-20" style="text-align:right">
          <el-button @click="gatherShow=false">关闭</el-button>
          <el-button type="primary" @click="gatherStart">开始采集</el-button>
        </div>
      </div>
    </el-dialog>
	</div>

</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	import buttonPermissions2 from '@/components/buttonPermissions2';
  import {
    taoKeProductTaglist,
    taoKeProductlist,
    taoKeProductsetMallIsOnSale,
    taoKeProductsetBoutique,
    taoKeProductdelete,
    taoKeProductupdate,
    taoKeProductgather,
    taoKeProductlastTaskInfo,
    taoKeProductupdateAll
  } from "@/api/sv3.0.0"
	export default {
		components: {
			buttonPermissions,
      buttonPermissions2
		},
		data() {
			return {
        result:{
          Id:0,
          RecordType:null,
          AddProductCount:0,
          SyncState:null
        },
        showHidden:false,
        listState:'1',
				listStateList: [{
						id: '1',
						value: '全部商品'
					},
					{
						id: '2',
						value: '精选商品'
					},
				],
        pageloading:false,
				searchKey: '',
				goodsLabel: null, //商品标签
        goodsLabelList:[],
				mallState: null, //商品品牌
				duoduoState: null,
				duoduoStateList: [
          {value:true,label:'上架'},
          {value:false,label:'下架'},
				],
				mallStateList: [
          {value:true,label:'商城内显示'},
          {value:false,label:'商城内隐藏'},
        ],
				groupData:[],
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				priceMin: '',
				priceMax: '',
				multipleSelection: [],
				loading: false,
				
				checked: false,
				isCurrentAll: false,
				baseAPI: config.BASE_URL,
				imgUrl: config.IMG_BASE,
				exportUrl: config.EXPORT_URL,


        gatherMsg:{},
        gatherShow:false,
        gatherLoading:false,
        gatherOption:[
          {value:1,label:'佣金比例'},
          {value:2,label:'佣金金额'},
        ]
			};
		},
		computed: {
			...mapGetters([
				'routerList',
				'IsMoreShop',
				'mallInfo',
				'ComponentId'
			]),
		},
		beforeMount() {
			this.getInfor()
      this.pageInit()
			this.getList()
		},
		methods: {
      async updateAll(){
        if(this.result.RecordType==2&&this.result.SyncState==0){
          this.$message.error('当前正在更新商品，请等待本次更新完成')
          return 
        }
        this.$confirm('是否确认更新全部商品数据？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'warning'
        }).then(async () => {
          try{
            let res = await taoKeProductupdateAll({PlatformType:1})
            if(res.IsSuccess){
              this.$message.success('操作成功')
              this.goodsFilter()
            }
          }finally{
            //
          }
        })
        
      },
      handleCommand(e){
        if(!this.multipleSelection||!this.multipleSelection.length){
          this.$message.error('请先选择商品')
          return
        }
        if(e==1){
          this.del(this.multipleSelection)
        }else if(e==2){
          this.batchUpdate()
        }else if(e==3){
          this.setWinnow(this.multipleSelection,false)
        }else if(e==4){
          this.setWinnow(this.multipleSelection,true)
        }else if(e==5){
          this.changeMallState(this.multipleSelection,false)
        }else if(e==6){
          this.changeMallState(this.multipleSelection,true)
        }
      },
      async changeMallState(rowlist,e){
        try{
          this.loading = true
          let res = await taoKeProductsetMallIsOnSale({
            ProductIds:rowlist.map(v=>v.Id),
            MallIsOnSale:e
          })
          if(res.IsSuccess){
            this.$message.success('操作成功')
            this.getList()
          }
        }finally{
          this.loading = false
        }
      },
      del(rowlist){
        this.$confirm('是否确认删除商品？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try{
            this.loading = true
            let res = await taoKeProductdelete({ProductIds:rowlist.map(v=>v.Id)})
            if(res.IsSuccess){
              this.$message.success('操作成功')
              this.getList()
            }
          }finally{
            this.loading = false
          }
        })
        
      },
      async setWinnow(rowlist,IsBoutique){
        try{
          this.loading = true
          let res = await taoKeProductsetBoutique({
            ProductIds:rowlist.map(v=>v.Id),
            IsBoutique:IsBoutique
          })
          if(res.IsSuccess){
            this.$message.success('操作成功')
            this.getList()
          }
        }finally{
          this.loading = false
        }
      },
      async toUpdate(row){
        try{
          this.loading = true
          let res = await taoKeProductupdate({Id:row.Id})
          if(res.IsSuccess){
            this.$message.success('操作成功')
            // this.pageInit()
            this.getList()
          }
        }finally{
          this.loading = false
        }
      },
      async batchUpdate(){
        try{
          this.loading = true
          for(let item of this.multipleSelection){
            await taoKeProductupdate({Id:item.Id})
          }
        }finally{
          this.$message.success('操作成功')
          this.getList()
          this.loading = false
        }
      },
      async pageInit(){
        try{
          this.pageloading = true
          let res = await taoKeProductlastTaskInfo()
          if(res.IsSuccess){
            this.result = res.Result
          }
        }finally{
          this.pageloading = false
        }
      },
      async gatherStart(){
        if(this.gatherMsg.TagType==1&&!this.gatherMsg.TaoKeQidianTagId){
          this.$message.error('请选择指定标签')
          return 
        }
        if(this.gatherMsg.chooseOpt==0){
          let str_MinPrice = String(this.gatherMsg.MinPrice)
          let str_MaxPrice = String(this.gatherMsg.MaxPrice)
          let MinPrice = Number(this.gatherMsg.MinPrice)
          let MaxPrice = Number(this.gatherMsg.MaxPrice)
          if((str_MinPrice&&(MinPrice<0.01||MinPrice>3000))||
            (str_MaxPrice&&(MaxPrice<0.01||MaxPrice>3000))||
            (str_MinPrice&&str_MaxPrice&&MinPrice>MaxPrice)){
            this.$message.error('请设置正确的价格区间')
            return 
          }
        }else if(this.gatherMsg.chooseOpt==1){
          let str_MinPromotion = String(this.gatherMsg.MinPromotion)
          let str_MaxPromotion = String(this.gatherMsg.MaxPromotion)
          let MinPromotion = Number(this.gatherMsg.MinPromotion)
          let MaxPromotion = Number(this.gatherMsg.MaxPromotion)
          if(this.gatherMsg.PromotionType==1){
            if((str_MinPromotion&&(MinPromotion<0.1||MinPromotion>50))||
              (str_MaxPromotion&&(MaxPromotion<0.1||MaxPromotion>50))||
              (str_MinPromotion&&str_MaxPromotion&&MinPromotion>MaxPromotion)){
              this.$message.error('请设置正确的佣金区间')
              return 
            }
          }else{
            if(str_MinPromotion&&(MinPromotion<0.01||MaxPromotion>500)){
              this.$message.error('请设置正确的佣金区间')
              return 
            }
          }
        }else if(this.gatherMsg.chooseOpt==2){
          if(String(this.gatherMsg.MinSaleCount)&&(this.gatherMsg.MinSaleCount<1||this.gatherMsg.MinSaleCount>100000000)){
            this.$message.error('请设置正确的销量区间')
            return 
          }
        }
        if(!this.gatherMsg.GatherCount&&(this.gatherMsg.GatherCount<1||this.gatherMsg.GatherCount>10000)){
          this.$message.error('采集数量请输入1~10000之间')
          return 
        }
        try{
          this.gatherLoading = true
          let gatherMsg = JSON.parse(JSON.stringify(this.gatherMsg))
          if(gatherMsg.chooseOpt!=0){
            gatherMsg.MinPrice = '' 
            gatherMsg.MaxPrice = ''
          }else{
            gatherMsg.PromotionType=0
          }
          // console.log(gatherMsg.chooseOpt,gatherMsg.MinPrice,gatherMsg.MaxPrice,1)
          if(gatherMsg.chooseOpt!=1){
            gatherMsg.MinPromotion = '' 
            gatherMsg.MaxPromotion = ''
          }else{
            if(gatherMsg.PromotionType==2){
              gatherMsg.MaxPromotion = ''
            }
          }
          // console.log(gatherMsg.chooseOpt,gatherMsg.MinPromotion,gatherMsg.MaxPromotion,2)
          if(this.gatherMsg.chooseOpt!=2){
            gatherMsg.MinSaleCount = ''
          }else{
            gatherMsg.PromotionType=3
          }
          // console.log(gatherMsg.chooseOpt,gatherMsg.MinSaleCount,3)
          gatherMsg.noError = true
          let res = await taoKeProductgather(gatherMsg)
          if(res.IsSuccess){
            this.$message.success('操作成功')
            this.gatherShow = false
            this.pageInit()
          }else if(res.ErrorCode==8000001){
            this.$confirm('拼多多风控提示：大量拉取进宝商品，深翻页占比过高，当前duoId被禁止商品搜索请求。请修改采集数量在1~200件之间', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '关闭',
              showConfirmButton:false,
              type: 'warning'
            }).catch(()=>{
              this.gatherShow = false
              this.pageInit()
            })
          }else{
            this.$message.error(res.Message)
          }
        }finally{
          this.gatherLoading = false
        }
      },
      toGather(){
        if(this.result.RecordType==1&&this.result.SyncState==0){
          this.$message.error('当前正在采集商品，请等待本次采集完成')
          return
        }
        this.gatherMsg = {
          PlatformType:1,
          Keywords:'',
          TagType:0,
          TaoKeQidianTagId:null,
          chooseOpt:null,
          MinPrice:'',
          MaxPrice:'',
          PromotionType:1,
          MinPromotion:'',
          MaxPromotion:'',
          MinSaleCount:'',
          GatherCount:100
        }
        this.gatherShow = true
      },
      clearSearch(){
        this.searchKey = ''
        this.goodsLabel = null
        this.mallState = null
        this.duoduoState = null
        this.priceMin = ''
        this.priceMax = ''
        this.goodsFilter()
      },
			mousereeEnter(row, column, cell, event) {
				this.groupData.map((item, index) => {
					if (item.ProductId == row.ProductId) {
						this.$set(this.groupData[index], 'isShoweite', true)
					}
					return item
				})
				this.groupData.reverse().reverse()
			},
			mouserLeave(row, column, cell, event) {
				this.groupData.map((item, index) => {
					if (item.ProductId == row.ProductId) {
						this.$set(this.groupData[index], 'isShoweite', false)
					}
					return item
				})
				this.groupData.reverse().reverse()
			},

			//筛选数据
			async getInfor() {
				try {

					let result1 = await taoKeProductTaglist({PlatformType:1})
					this.goodsLabelList = result1.Result

				} catch (e) {
					console.log(e)
				} finally {

				}

			},

			// 获取商品列表
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey, // 搜索关键字
            TaoKeQidianTagId:this.goodsLabel,
            MallIsOnSale:this.mallState,
            // TaoKeIsOnSale:this.duoduoState,
            MinPrice:this.priceMin,
            MaxPrice:this.priceMax,
            IsBoutique:this.listState=='1'?null:true,
            PlatformType:1,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await taoKeProductlist(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
					this.groupData.map(item => {
						item.isShoweite = false
					})


				} catch (e) {
					console.log(e)
				} finally {
					setTimeout(() => {
						this.loading = false
					}, 500)

				}

			},
			//顶部tab切换事件
			filterFun(tab, event) {
				this.goodsFilter()
        this.$nextTick(()=>{
				  this.checked = false;
          this.$refs['multipleTable'].clearSelection();
        })
			},
			// 搜索
			goodsFilter() {
        this.pageInit()
				this.currentPage = 1;
				this.getList();
			},
			handleSelectionChange(val) {

				this.multipleSelection = val;
				this.isCurrentAll = val.length == this.groupData.length ? true : false
				if (this.isCurrentAll) {
					this.checked = true
				} else {
					this.checked = false
				}
			},
			tabCheckedChange() {
				this.$refs['multipleTable'].toggleAllSelection();
			},


			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},



			shareMaterial(item) {
				this.setSharedMaterial(item);
			},


		}
	};
</script>

<style lang = "less" scoped>
.tips{
  background: #FDF6EC;
  padding: 10px;
  font-size: 14px;
  color: #FDA23CFF;
}
.text-right-80{
  text-align: right;
  width:80px;

}
.absolute-icon{
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 100;
  display: flex;
  align-items: center;
  height: 110px;
  .side{
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
    color: #C0C4CCFF;
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }
  .content{
    width: 110px;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
    border-radius: 10px 0px 0px 10px;
    transition: all 0.2s linear;
    .icon-box{
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img{
      width: 60px;height: 60px;
      transition: all 0.2s linear;
    }
    .newly{
      height: 14px;
      line-height: 14px;
      overflow: hidden;
      transition: height 0.2s linear;
    }
    .bottom{
      height: 26px;
      width: 100%;
      line-height: 26px;
      font-size: 14px;
      color: white;
      text-align: center;
      border-radius: 0px 0px 0px 10px;
      overflow: hidden;
      transition: height 0.2s linear;
    }
  }
  &.packUp{
    .content{
      width: 40px;
      height: 40px;
      img{
        width: 24px;
        height: 24px;
      }
      .newly{
        height: 0px;
      }
      .bottom{
        height: 0px;
      }
    }
    .side{
      transform: rotate(-180deg);
    }
  }
}
.BtnMask{
  /* pointer-events: auto !important; */
  background: #8cc5ff;
  border-color: #8cc5ff;
}
.table{
  ::v-deep .el-table__row{
    background: white;
  }
  ::v-deeptr{
    background-color: #f8f8f9
  }
}
.sel-noborder{
	::v-deep input{
		border:none
	}
}
.tableFont{
	background:#F5F5F5;
	color:#909399;
	border: 1px solid #EBEEF5;
	height: 44px;
	line-height: 44px;
	padding-left: 10px;
	font-weight: bold;
}

	.yellowbox{
		width: 80%;
		height: 36px;
		background: #FDF6EC;
		border-radius: 2px 2px 2px 2px;
		margin:0px auto;
		opacity: 1;
		border: 1px solid #E6A23C;
		font-size: 14px;
		font-weight: 400;
		color: #E6A23C;
		line-height: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.yellowbox{
		width: 80%;
		height: 36px;
		background: #FDF6EC;
		border-radius: 2px 2px 2px 2px;
		margin:0px auto;
		opacity: 1;
		border: 1px solid #E6A23C;
		font-size: 14px;
		font-weight: 400;
		color: #E6A23C;
		line-height: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
</style>

<style lang="less">
	.virtualsalesBox {
		::v-deep .el-dialog__body {
			padding: 0 20px 10px !important
		}
	}

	.nameboxClass {
		.el-dialog__body {
			padding: 0;
		}

	}

	.ml-goodList {
		.good-tab {
			position: relative;

			.tab-btn {
				position: absolute;
				right: 10px;
				top: 0px;
				z-index: 99;
			}
		}

		.product-info>img {
			width: 80px;
			height: 80px;
		}

		.setPricebos {
			.el-form-item {
				margin-bottom: 0;
			}
		}

		.el-dialog__body {
			padding: 10px 20px !important
		}

		.bottom_delete {
			border: 1px solid #f5f5f5;
			margin-left: 10px;
			padding: 5px 13px;
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.pointers {
			cursor: pointer;
		}

		.sorts-names {
			max-height: 400px;
			overflow: auto;
			margin-top: 20px;
		}

		.sorttable-top {
			width: 100%;
			background: #f8f8f9;
			padding: 15px;
		}

		.sortTable-body {
			width: 100%;
			padding: 15px;
		}
	}

	.tool-style {
		// width: 80px;
	}

	.tablered {
		color: red;
		vertical-align: -3px;
		margin-right: 5px;
	}
	.dialogtitle{
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-size: 18px;
		/* font-weight: bold; */
		color: #303133;
	}
	.agrementcontent{
		max-height:500px;
		overflow:hidden;
		overflow-y:auto;
		/* border:1px solid black; */
		width: 95%;
		margin:0px auto;
		// white-space: pre;
		color: #606266;
		font-size: 14px;
	}
	.flexCol-center{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.nativeInput:focus{
		border-color: #409EFF;
		// outline: none;
		outline-color: #409EFF;;
		outline-width: 1px;
	}
	
	.inputTextAlignLeft input{
		text-align: left!important;
	}
</style>
